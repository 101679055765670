import { Box, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";

const row_2024 = [
    {div: "MPO", name: "Bryce Kraushar"},
    {div: "MA1", name: "Tim Farrell"},
    {div: "MA40", name: "Dan Edinborough 2x"},
    {div: "FA40", name: "Susan Chrapchynski Evans"},
    {div: "MA50", name: "Clark Greenfield"},
    {div: "MA2", name: "Anton Sawka"},
    {div: "MA3", name: "Sam Ritchot"},
    {div: "FA1", name: "-"},
    {div: "FA2", name: "Maya Woods"},
    {div: "FA3", name: "-"},
    {div: "Putting Champion", name: "Marshall Toews 2x"}
];

const row_2023 = [
    {div: "MPO", name: "Marshall Toews"},
    {div: "MA1", name: "Mark Eldridge"},
    {div: "MA40", name: "Dan Edinborough"},
    {div: "MA2", name: "Sunny Dhillon"},
    {div: "MA3", name: "Trevor Menard"},
    {div: "FA1", name: "Camilla Tang"},
    {div: "FA2", name: "Sarah Mitchell"},
    {div: "FA3", name: "Dinah Talgoy"},
    {div: "Putting Champion", name: "Marshall Toews"},
    {div: "Kilter Long Drive", name: "David Sims"}
];

export function PastChampions() {
    return (
        <Box m="1rem" sx={{ overflowY: 'auto', overflowX: 'hidden'}}>
            <Stack direction="column" alignItems="center" mr="1rem">
                <Typography variant="h6">
                    2024
                </Typography>
                <Table sx={{ width: 400, margin: 'auto'}}>
                    <TableBody sx={{textAlign: 'center'}}>
                        <TableRow>
                            <TableCell />
                            <TableCell />
                        </TableRow>
                        {row_2024.map((row) => (
                            <TableRow>
                                <TableCell>
                                    {row.div}
                                </TableCell>
                                <TableCell>
                                    {row.name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Typography variant="h6" mt="2rem">
                    2023
                </Typography>
                <Table sx={{ width: 400, margin: 'auto'}}>
                    <TableBody sx={{textAlign: 'center'}}>
                        <TableRow>
                            <TableCell />
                            <TableCell />
                        </TableRow>
                        {row_2023.map((row) => (
                            <TableRow>
                                <TableCell>
                                    {row.div}
                                </TableCell>
                                <TableCell>
                                    {row.name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Stack>
        </Box>
    )   
}